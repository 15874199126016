import type { RegisterClientRequest } from '../supabase/functions/client-register';
import type { Database } from '../supabase/types/database.types';

export interface InvokeResponse {
  data?: any;
  error?: {
    message: string;
    status: number;
  };
}

export const useApprovalApi = () => {
  const session = useSupabaseSession();
  const supabase = useSupabaseClient<Database>();

  const getAuthHeaders = () => {
    return {
      Authorization: `Bearer ${session.value?.access_token}`,
    };
  };

  const registerClient = async (
    registerClientRequest: RegisterClientRequest,
  ) => {
    const response = (await $fetch('/api/client-register', {
      method: 'POST',
      headers: getAuthHeaders(),
      body: registerClientRequest,
    })) as InvokeResponse;

    return response;
  };

  const inviteClient = async (email: string, addIfNotExists?: boolean) => {
    const response = (await $fetch('/api/client-invite', {
      method: 'POST',
      headers: getAuthHeaders(),
      body: { email, addIfNotExists },
    })) as InvokeResponse;

    return response;
  };

  const getClientByEmail = async (email: string) => {
    return await supabase
      .from('clients')
      .select('*')
      .eq('email', email)
      .maybeSingle();
  };

  const updateOptionalClientInformation = async (
    email: string,
    optionalInformation: {
      name?: string;
      telegram?: string;
      discord?: string;
      contribution?: string;
    },
  ) => {
    return await supabase
      .from('clients')
      .update(optionalInformation)
      .eq('email', email);
  };

  const getClientsByEmailPattern = async (
    emailPattern: string,
    range: { from: number; to: number },
    orderBy?: { column: string; order: 'asc' | 'desc' },
    accepted?: boolean,
  ) => {
    const query = supabase
      .from('clients')
      .select('*')
      .order(orderBy?.column ?? 'created_at', {
        ascending: orderBy?.order === 'asc',
      })
      .like('email', emailPattern)
      .range(range.from, range.to);

    if (accepted !== undefined) {
      query.eq('is_accepted', accepted);
    }

    return await query;
  };

  const getClientsByRange = async (
    range: { from: number; to: number },
    orderBy?: { column: string; order: 'asc' | 'desc' },
    accepted?: boolean,
  ) => {
    const query = supabase
      .from('clients')
      .select('*')
      .order(orderBy?.column ?? 'created_at', {
        ascending: orderBy?.order === 'asc',
      })
      .range(range.from, range.to);

    if (accepted !== undefined) {
      query.eq('is_accepted', accepted);
    }

    return await query;
  };

  const getDailyStats = async (days: number) => {
    return await supabase
      .from('daily_stats')
      .select('*')
      .order('created_at', { ascending: false })
      .limit(days);
  };

  // Return the hook functions
  const generateSpecialCode = async (maxUses: number) => {
    const response = (await $fetch('/api/generate-special-code', {
      method: 'POST',
      headers: getAuthHeaders(),
      body: { maxUses },
    })) as InvokeResponse;

    return response;
  };

  return {
    registerClient,
    inviteClient,
    getClientByEmail,
    updateOptionalClientInformation,
    getClientsByEmailPattern,
    getClientsByRange,
    getDailyStats,
    generateSpecialCode,
  };
};
