export interface AdminPage {
  label: string;
  icon: string;
  to: string;
}

export const ADMIN_PAGES: AdminPage[] = [
  // The first page will be the default landing page
  // when an admin user is authenticated
  {
    label: 'Daily Stats',
    icon: 'i-heroicons-chart-bar',
    to: '/admin/daily-stats',
  },
  {
    label: 'Registered Clients',
    icon: 'i-heroicons-user-group',
    to: '/admin/registered-clients',
  },
  {
    label: 'Register / Invite Clients',
    icon: 'i-heroicons-user-circle',
    to: '/admin/register-invite-clients',
  },
  {
    label: 'Special Codes',
    icon: 'i-heroicons-command-line',
    to: '/admin/special-codes',
  },
];
